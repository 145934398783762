$textColor: #000000;
body.light {
    --text-color: #000;
    --surface-c: #EEE;
    --surface-d: #e0dfe0;
    //--surface-ground: #EEE;
    --surface-ground: #bcc7d8;
    color: $textColor;

    .p-tabview {
        .p-tabview-nav-content {
            .p-tabview-nav {
                background: var(--surface-c) !important;

                li {
                    a {
                        background: var(--surface-500) !important;
                        color: var(--primary-color-text) !important;
                    }
                }
            }
        }
    }

    .p-accordion {
        .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
            background: var(--surface-500) !important;
            color: var(--primary-color-text) !important;
        }

        .p-accordion-content {
            color: $textColor;
            background: var(--surface-d) !important;
        }
    }

    .toolbar {
        color: $textColor;
        background: var(--surface-c) !important;
    }


    .p-card {
        color: $textColor;

        &.p-component {
            background: var(--surface-c) !important;
        }
    }

    .p-datatable {
        .p-datatable-tbody {
            & > tr {
                & > td {
                    color: $textColor;
                }
            }
        }

        &.p-datatable-hoverable-rows {
            .p-datatable-tbody {
                & > tr:not(.p-highlight):hover {
                    background: #DDD;
                    transition: 0.1s all;
                }
            }
        }

        &.p-datatable-striped {
            .p-datatable-tbody {
                & > tr:nth-child(even) {
                    background: #f6f6f6;
                }
            }
        }
    }

    .p-panel {
        .p-panel-content {
            background: #f6f6f6;
        }
    }


    .p-column-filter-overlay {
        color: $textColor;
        .p-column-filter-row-items {
            .p-column-filter-row-item {
                color: $textColor;
            }
        }
    }


    .p-multiselect-panel {
        color: $textColor;
        .p-multiselect-items {
            .p-multiselect-item {
                color: $textColor;
            }
        }
    }

    .p-inputtext {
        color: $textColor;
    }

}
