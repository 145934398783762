body.dark {
    .p-card {
        &.p-component {
            background: var(--surface-d) !important;
        }
    }

    .p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider {
        background: #17212f;
    }

}
