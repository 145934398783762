html, body {
    height: 100%;
}

body {
    font-family: var(--font-family);
    margin: 0;
}

.scrollable-body {
    display: flex;
    flex-flow: column;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.content-section {
    background: var(--surface-a);
    color: var(--text-color);
}

.p-component-overlay { //TODO: primeNG dovrebbe applicare in automatico questo stile all'overlay ma non lo fa
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

:root {
    --error-color: #D32F2F;
    --warning-color: #ee9e4c;
    --success-color: rgb(61, 140, 64);
    --client-message-color: #ffc610;
    --provider-message-color: #6656e5;
    --chat-message-color: #046ac2;
}

.text-error {
    color: var(--error-color);
}

.text-warning {
    color: var(--warning-color);
}

.text-success {
    color: var(--success-color);
}

.background-error {
    background-color: var(--error-color);
}

.background-warning {
    background-color: var(--warning-color);
}

.background-success {
    background-color: var(--success-color);
}

// Fix perché sembra ci sia un bug su primeng che non nasconde i campi
// con questa classe
.p-hidden-accessible {
    display: none;
}

.p-tooltip {
    position: absolute;
}

.p-button-xs {
    padding: 2px 4px;
    margin: 0;
    line-height: 1em;
    font-size: 0.8em;
}

.default-text {
    color: var(--text-color);
}


.sm\:table-cell {
    display: table-cell !important;
}


@media screen and (min-width: 768px) {
    .md\:table-cell {
        display: table-cell !important;
    }
}

@media screen and (min-width: 992px) {
    .lg\:table-cell {
        display: table-cell !important;
    }
}

@media screen and (min-width: 1200px) {
    .xl\:table-cell {
        display: table-cell !important;
    }
}

a {
    color: var(--primary-color);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 6px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.7), 0 0 2px 2px rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.7), 0 0 2px 2px rgba(0, 0, 0, 0.2);
}


p-table div.p-datatable-hoverable-rows table tbody tr:hover td {
    color: var(--primary-color); // TODO vedere se ha senso applicarlo globalmente
}


.menu-500px {
    .p-menu-list, .p-menuitem, .p-menu-text {
        width: 500px;
    }

    width: 500px;
}

hr {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}

.dark .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: rgba(255, 255, 255, .2);
}

.dark {
    .highlighted-text {
        font-weight: bold;
        background-color: var(--yellow-800);
    }
}

.light {
    .highlighted-text {
        background-color: var(--yellow-200);
        font-weight: bold;
    }
}

.tr-hover-highlight tr:hover td {
    background-color: rgb(120 120 120 / 0.25);
}
